import { memo, useCallback } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'

import { clx } from '@utils'
import s from './rating.module.scss'

function Rating({
   ratingNumber,
   reviewsCount,
   productCode,
   onlyStars = false,
   className,
}) {
   const renderStarsList = useCallback(() => {
      const starsList = []

      for (let index = 0; index < 5; index++) {
         const imageName = index < ratingNumber ? 'star' : 'empty_star'

         starsList.push((
            <Image
               key={ index }
               src={ `/images/common/${imageName}.svg` }
               width={ 20 }
               height={ 20 }
               alt=""
            />
         ))
      }

      return starsList
   }, [ratingNumber])

   if (ratingNumber === 0) {
      return <div className={ s.wrapper } />
   }

   return (
      <div className={ clx(s.wrapper, className) }>
         <div className={ s.stars }>
            { renderStarsList() }
         </div>

         { !onlyStars && (
            <Link href={ `/products/${ productCode }?tab=reviews` }>
               <a className={ s.reviews_count }>
                  ({ reviewsCount })
               </a>
            </Link>
         ) }
      </div>
   )
}

Rating.propTypes = {
   ratingNumber: PropTypes.number.isRequired,
   onlyStars: PropTypes.bool,
   productCode: PropTypes.number
}

export default memo(Rating)
